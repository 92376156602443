<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<{
  show?: boolean
  title?: string
  closable?: boolean
  slideFrom?: 'left' | 'right'
  id?: string
  as?: string
}>(), {
  as: 'div',
})

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'after-leave'): void
}>()

const slideFrom = computed(() => props.slideFrom || 'right')

const classNames = computed(() => {
  if (slideFrom.value === 'right') {
    return {
      dialogPanelContainer: 'right-0 pl10',
      dialogPanel: 'rounded-l-xl',
      dialogPanelEnter: 'translate-x-full',
      dialogPanelEnterTo: 'translate-x-0',
      dialogPanelLeave: 'translate-x-0',
      dialogPanelLeaveTo: 'translate-x-full',
    }
  }

  return {
    dialogPanelContainer: 'left-0 pr10',
    dialogPanel: 'rounded-r-xl',
    dialogPanelEnter: '-translate-x-full',
    dialogPanelEnterTo: 'translate-x-0',
    dialogPanelLeave: 'translate-x-0',
    dialogPanelLeaveTo: '-translate-x-full',
  }
})
</script>

<template>
  <HTransitionRoot
    :show="show" as="template"
    @after-leave="emit('after-leave')"
  >
    <HDialog
      :as="as"
      class="fixed inset-0 z100 overflow-hidden"
      @close="emit('close')"
    >
      <div class="fixed inset-0 overflow-hidden">
        <HTransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="absolute inset-0 bg-slate-900/25 backdrop-blur-sm" />
        </HTransitionChild>
        <div class="fixed inset-y-0 max-w-full flex" :class="classNames.dialogPanelContainer">
          <HTransitionChild
            as="template"
            enter="transform transition ease-in-out duration-300"
            :enter-from="classNames.dialogPanelEnter"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-300"
            leave-from="translate-x-0"
            :leave-to="classNames.dialogPanelLeaveTo"
          >
            <div :id="id" class="max-w-md w-screen @container">
              <HDialogPanel
                id="drawer-panel"
                class="h-full flex flex-col overflow-y-auto bg-white shadow-xl ring-1 ring-black/10"
                :class="classNames.dialogPanel"
              >
                <div v-if="!$slots.header" class="flex items-start justify-between border-b border-slate-200 px-4 py-6 sm:px-6">
                  <HDialogTitle id="drawer-title" class="text-context fw600" n="slate9 lg">
                    {{ title }}
                  </HDialogTitle>

                  <div v-if="closable" class="ml-3 h-7 flex items-center">
                    <NButton
                      id="drawer-close"
                      n="lg borderless"
                      icon="i-heroicons:x-mark"
                      class="p-0.25em children:m-0 sm:-m-2"
                      @click="$emit('close')"
                    />
                  </div>
                </div>

                <slot v-else name="header" />

                <div class="flex-1 overflow-y-auto">
                  <slot />
                </div>

                <div v-if="$slots.footer" class="border-t n-border-base">
                  <slot name="footer" />
                </div>
              </HDialogPanel>
            </div>
          </HTransitionChild>
        </div>
      </div>
    </HDialog>
  </HTransitionRoot>
</template>
